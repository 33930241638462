import * as React from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import Popover from "./popover/Popover";
import PopoverOptionsList from "./popover/PopoverOptionsList";
import IconButton from "./buttons/IconButton";
import { colors } from "../../styles/colors";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import UnreadBadge from "./UnreadBadge";
import Cond from "../meta/Cond";
import { globalStyles } from "../../styles/global";

function OverflowMenu({ options, maxPerPage, badgeValue, forceOverflow }) {
	const [overflowAfter, setoverflowAfter] = React.useState(0);
	const popoverRef = React.useRef();

	const window = Dimensions.get("window");

	React.useEffect(() => {
		if (window.width > 450) setoverflowAfter(1);
		else if (window.width > 500) setoverflowAfter(2);
		else if (window.width > 550) setoverflowAfter(3);
	}, [window.width]);

	// Close the modal when any of the option is pressed
	if (popoverRef.current) {
		options = options.map((o) => ({
			...o,
			onPress: () => {
				popoverRef.current.hide();
				o.onPress();
			},
		}));
	}

	const visibleOptions = options.length === 1 && !forceOverflow ? options : options.slice(0, overflowAfter);
	const overflowingOptions = options.length === 1 && !forceOverflow ? [] : options.slice(overflowAfter);

	return (
		<View style={[globalStyles.rc]}>
			{visibleOptions.map((opt, index) => (
				<IconButton
					key={index}
					icon={{ type: MaterialCommunityIcons, name: "help" }}
					transparent
					{...opt}
					title={null}
				/>
			))}
			<Cond show={overflowingOptions.length}>
				<Popover ref={popoverRef} popoverComponent={<PopoverOptionsList options={overflowingOptions} />}>
					<IconButton
						icon={{
							type: MaterialIcons,
							name: "more-vert",
							color: global.colors.textDefault,
						}}
						transparent
						onPress={() => popoverRef.current.show()}
					/>
					{!!badgeValue && (
						<UnreadBadge
							value={badgeValue}
							important
							style={{ position: "absolute", bottom: 0, left: -8 }}
						/>
					)}
				</Popover>
			</Cond>
		</View>
	);
}

export default OverflowMenu;


