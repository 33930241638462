import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { fetchSheetValuePairsIfNeeded } from "../../store/slices/sheetValuePairsSlice";
import { isValidCommand, sendRoll } from "../../tools/diceRoll";
import { isWeb } from "../../tools/generic";
import { filterDefaultLabels } from "../../tools/sheets";
import AppText from "../generic/AppText";
import InputFieldButton from "../generic/buttons/InputFieldButton";
import InputField from "../generic/InputField";
import ModalScreen from "../generic/modal/ModalScreen";
import DiceCommandResult from "../lines/DiceCommandResult";
import CondView from "../meta/CondView";
import RGIcon from "../RGIcon";
import ListCommandsHelpButton from "./ListCommandsHelpButton";

import { isGM } from "../../tools/games";

function AdvancedRollModal({ route, dispatch, currentCharacter, sheetValuePairs, partyId, gmInfo, gameId, navigation, game }) {
	const { editedFieldProps, isRequest, charactersSelected, timer } = route.params || {};

	const [rollName, setrollName] = React.useState();
	const [rollCommand, setrollCommand] = React.useState();

	const [toGMOnly, setToGMOnly] = React.useState(false);

	sheetValuePairs = sheetValuePairs ? sheetValuePairs.filter(filterDefaultLabels) : [];

	React.useEffect(() => {
		dispatch(fetchSheetValuePairsIfNeeded(currentCharacter?.sheet || game.sheet_template));
	}, []);

	React.useEffect(() => {
		if (editedFieldProps) {
			setrollName(editedFieldProps[0].value);
			setrollCommand(editedFieldProps[1].value);
		}
	}, [editedFieldProps]);

	const [isValid, error] = isValidCommand(rollCommand, [], currentCharacter);

	const roll = React.useCallback(() => {
		if (charactersSelected?.length) {
			apiFetch(`games/${gameId}/roll-requests`, "POST", {
				name: rollName,
				command: rollCommand,
				targets: charactersSelected.map((c) => c.id),
				timer,
			});
			navigation.navigate("Game");
			return;
		}

		sendRoll(gameId, partyId, rollCommand, rollName, [], currentCharacter, toGMOnly, gmInfo, dispatch);
		navigation.navigate("Game");
	}, [rollCommand, rollName, isValid, currentCharacter, gameId, charactersSelected, partyId, timer, toGMOnly]);

	React.useEffect(() => {
		if (charactersSelected?.length && isRequest && isValid) {
			roll();
		}
	}, [charactersSelected, isRequest, isValid]);

	const nameProps = {
		key: "rollName",
		label: _("Roll title (optional)"),
		value: rollName,
	};

	const commandProps = {
		key: "rollCommand",
		label: _("Roll command", "roll+ command input field"),
		value: rollCommand,
		multiline: true,
		placeholder: _("Ex: attacks for [[1d20+STR]] and deals [[1d10]] damage", "roll+ placeholder"),
		autocompleteProps: {
			itemsPool: sheetValuePairs,
			itemToStringMap: sheetValuePairs.map((svp) => ({ item: svp, string: svp.label })),
			filterItemsByKey: "label",
			// Remember to double the slashes, as the string will "consume" single slashes out
			wordBoundaryRegexString: "(\\[\\[)|(]])|[+><=-]|(\\d+d)",
			wordBoundaryRegexStringFlags: "gm",
			appendToAutocomplete: "",
		},
	};

	const goFullScreen = (focusedKey) => {
		navigation.navigate({
			name: "EditRollCommand",
			params: {
				inputFieldProps: [nameProps, commandProps],
				previousScreenName: route.name,
				focusedKey: focusedKey,
			},
			merge: true,
		});
	};

	const gm = isGM(gameId);

	const options = [
		{
			title: _("Back"),
			type: "shifted_back_button", // Type is checked in ButtonFooter. Allows style to match back buttons even when not first in list.
			onPress: () => {
				navigation.goBack();
			},
		},
	];

	// If not the GM, add the option to whisper to the GM
	!gm && options.unshift(
		{
			title: _("To GM only"),
			type: "checkbox", // Type is checked in ButtonFooter.
			onChange: () => { setToGMOnly(!toGMOnly); },
			checked: { toGMOnly },
		}
	);

	isRequest &&
		options.push({
			title: _("Choose characters", "request roll"),
			disabled: !isValid,
			onPress: () => {
				navigation.navigate({
					name: "SelectRequestTargets",
					params: {
						pcOnly: true,
						goToScreen: "SetTimerModal",
						gameId,
						nextButtonTitle: _("Set timer", "set timer for roll request"),
					},
					merge: true,
				});
			},
		});
	!isRequest &&
		options.push({
			title: _("Roll"),
			disabled: !isValid,
			onPress: roll,
		});

	const inputFields = isWeb() ? (
		<>
			<InputField {...nameProps} onChangeText={setrollName} />
			<InputField {...commandProps} onChangeText={setrollCommand} />
		</>
	) : (
		<>
			<InputFieldButton {...nameProps} onPress={() => goFullScreen("rollName")} />
			<InputFieldButton {...commandProps} onPress={() => goFullScreen("rollCommand")} />
		</>
	);

	return (
		<ModalScreen
			useButtonFooter
			style={styles.container}
			borderless
			options={options}
			helpButton={<ListCommandsHelpButton />}
			title={isRequest ? _("Request roll", "roll dice menu option") : _("Roll+")}
			overflowOptions={
				isRequest
					? [
						{
							title: _("Use macro", "use a macro to request a roll"),
							icon: { type: RGIcon, name: "die" },
							subicon: { type: MaterialIcons, name: "play-arrow" },
							onPress: () => navigation.navigate("SelectMacroModal", { isRequest: true }),
							allowLegendaryShare: true,
						},
					]
					: null
			}
			wide
		>
			{!isWeb() && inputFields}
			<CondView show={rollCommand} style={styles.container}>
				<AppText bold hide={!rollCommand}>
					{_("Preview", "roll+ command result preview")}
					<AppText color="hint">
						{_(
							" (actual roll results will differ)",
							"warns that the roll result preview is not the actual roll result"
						)}
					</AppText>
				</AppText>
				<AppText>
					{!!rollCommand && (
						<DiceCommandResult command={rollCommand} targets={[]} author={currentCharacter} />
					)}
				</AppText>
			</CondView>
			{isWeb() && inputFields}
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const game = state.games[state.games.currentId];
	const currentCharacter = getCurrentCharacter(state);
	const gmInfo = state.games[game.id]?.game_master;
	return {
		game: game,
		gameId: game.id,
		currentCharacter,
		sheetValuePairs: state.sheetValuePairs[currentCharacter?.sheet || game.sheet_template],
		partyId: state.parties[game.id]?.current?.id,
		gmInfo,
	};
};

export default connect(mapStateToProps)(AdvancedRollModal);

const styles = StyleSheet.create({
	container: {
		margin: 16,
	},
});
